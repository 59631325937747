.section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 7em;
}

.back-button {
    width: 150px;
    margin-top: 5em;
}
